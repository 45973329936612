<template>
  <div>
    <div>
      <p>{{ $t('dashboard.aquisition-booster.messages.reactivateContacts') }}</p>
      <div
        v-if="!hasImportedLeads"
        class="d-flex flex-column"
      >
        <span class="text-subtitle-2 font-weight-regular">{{ $t('dashboard.aquisition-booster.messages.importTip') }}</span>
        <v-btn
          outlined
          color="primary"
          :class="{'w-full':$vuetify.breakpoint.smAndDown, 'small-button':$vuetify.breakpoint.mdAndUp}"
          @click="triggerAppcuesFlow({slug:'import-contact'})"
        >
          {{ $t('dashboard.aquisition-booster.buttons.importContacts') }}
        </v-btn>
      </div>
      <span
        v-else
        class="text-subtitle-2 font-weight-regular"
      >
        {{ $t('dashboard.aquisition-booster.messages.alreadyImported') }}
        <span @click="triggerAppcuesFlow({slug:'import-contact'})">{{ $t('dashboard.aquisition-booster.messages.importMoreContacts') }}</span>
      </span>

      <div class="d-flex flex-column mt-4">
        <span
          v-if="!hasImportedLeads"
          class="text-subtitle-2 font-weight-regular"
        >{{ $t('dashboard.aquisition-booster.messages.reactivateWithEmails') }}</span>
        <span
          v-else
          class="text-subtitle-2 font-weight-regular"
        >{{ $t('dashboard.aquisition-booster.messages.reactivateWithEmails') }}</span>
        <v-btn
          :outlined="!hasImportedLeads"
          color="primary"
          :class="{'w-full':$vuetify.breakpoint.smAndDown, 'small-button':$vuetify.breakpoint.mdAndUp}"
          @click="triggerAppcuesFlow({slug:'invite-contact'})"
        >
          {{ $t('dashboard.aquisition-booster.buttons.startBooster') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import HAS_IMPORTED_LEADS from './HasImportedLeads.gql'
import { triggerAppcuesFlow } from '@/lib/appcues'

export default {
  methods: {
    triggerAppcuesFlow
  },
  apollo: {
    hasImportedLeads: {
      query: HAS_IMPORTED_LEADS
    }
  }

}
</script>
<style scoped>
.small-button{
  width: 300px;
}
</style>
